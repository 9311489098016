<div
  [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <h1>Accessibility</h1>
  <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />
  <div class="container-card">
    <mat-card
      appearance="outlined"
      class="mat-component-background mat-elevation-z2"
    >
      <mat-card-header>
        <mat-card-title>Chef's Special</mat-card-title>
        <mat-card-subtitle>All-Inclusive All Day Every Day</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Planet VG empowers all climate-conscious and vegan people and businesses alike to change the world for the better. Our goal is to bring those people closer together. These bonds are needed if we want to create a better place for animals and people as well. A place where everyone is welcome, included, and treated fairly.
          </p>
          <p>
            To support our mission, we are committed to ensuring that our website and apps are open and accessible to the broadest audience possible, inclusing those with visual, hearing and other disabilities.
          </p>
          <p>
            We strive to continually improve the user experience for everyone by consulting relevant accessibility standards.
          </p>
          <p>If you have questions, comments, or encounter any difficulty relating to the accessibility of our website, please contact the Planet VG Accessiblity Chefs at <a href="mailto:accessibility@almostlevel5.com">accessibility&#64;almostlevel5.com</a></p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<app-bottom-menu></app-bottom-menu>
