<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>
        {{ explanationStep.title }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ explanationStep.subtitle }}
      </mat-card-subtitle>
    </mat-card-header>

    <img mat-card-image src="{{ explanationStep.imageUrl }}" alt="Explanation Step {{ explanationStep.title }}" />

    <mat-card-content class="container-card-content">
      <div class="content">
        <p>
          {{ explanationStep.description }}
        </p>
        <mat-list>
          <mat-list-item *ngFor="let step of explanationStep.steps" [ngClass]="{
          'list-item-medium': isMedium(),
          'list-item-xsmall': isXSmall()
        }">
            <span matListItemIcon>
              <mat-icon color="primary">eco</mat-icon>
            </span>
            <div matListItemTitle class="list-item-title">{{ step }}</div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
    <mat-card-actions fxFlex fxLayout="column" fxLayoutAlign="space-between end">
      <div *ngIf="explanationStep.showPricingAction" class="container-card-actions">
        <button mat-raised-button color="primary" routerLink="/pricing">
          <mat-icon>app_registration</mat-icon>
          Get started today
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>