<div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div class="container-banner">
        <h1>Help Center</h1>
        <div class="banner-content" *ngIf="!(isAuthenticated$ | async).isAuthenticated">
            <h2>
                We hope you'll find your answer here. You can always 
                <a href="/contact">contact</a> the Planet VG Chefs.
            </h2>
        </div>
    </div>

    <div class="container-category">
        <h2>General Questions</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What's the difference between the Planet VG app and the Planet VG
                    Store app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                The Planet VG app is the app for <u>your customers</u>. Using this app
                your customers can collect stamps and vouchers. They can also manage
                their club memberships.
            </p>
            <p>
                The Planet VG Store app is the app for <u>your employees</u>. Using this
                app your employees can issue stamps for a specific stamp cards or
                redeem vouchers.
            </p>
            <p><em>And, while we are at it;</em></p>
            <p></p>
            <p>
                The Planet VG Portal is the portal where you can manage your promotions,
                stamp cards, store locations, and your company. You can also
                manage your subscription within the Planet VG Partner Portal.
            </p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>How to manage your business</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How to change the billing information? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to your profile menu and click the billing menu option.</p>
            <p>
                Select the <strong>Billing Information</strong> tab. And press the
                <mat-icon>edit</mat-icon> <strong>Billing Portal</strong> button.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How to change your payment method? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to your profile menu and click the billing menu option.</p>
            <p>
                Select the <strong>Invoices</strong> tab. And press the
                <mat-icon>payments</mat-icon> <strong>Billing Portal</strong> button.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How to change your subscription plan?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to your profile menu and click the billing menu option.</p>
            <p>
                Select the <strong>Orders</strong> tab. And press the
                <mat-icon>storefront</mat-icon>
                <strong>Upgrade Subscription Plan</strong> button.
            </p>
            <p>
                <em>Be aware</em>. When you downgrade your plan, you will lose certain
                features. Active promotions and stamp cards will be deactivated. After the
                downgrade, you decide which promotion and stamp card you want to reactivate.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> What's on the dashboard? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You will see the following:</p>
            <ul>
                <li>
                    Quick overview of your active store locations, promotions and stamp cards.
                </li>
                <li>Total Members Chart. Overview of your members.</li>
                <li>
                    Promotion Chart. Every active promotion has a chart displaying the following:
                    <ul>
                        <li>Engagements: the amount of people that claimed the voucher.</li>
                        <li>
                            Issued Vouchers: the amount of people that received a voucher.
                        </li>
                        <li>
                            Redeemed Vouchers: the amount of people that redeemed the voucher.
                        </li>
                    </ul>
                    The ratio between <em>issued vouchers</em> and
                    <em>redeemed vouchers</em> is important to measure the actual success
                    of your promotion.
                </li>
                <li>
                    Stamp Card Chart. Every active stamp card has a chart
                    displaying the following:
                    <ul>
                        <li>
                            Participants: the amount of people that is saving on a stamp card.
                        </li>
                        <li>
                            Issued Vouchers: the amount of people that received a voucher.
                        </li>
                        <li>
                            Redeemed Vouchers: the amount of people that redeemed the voucher.
                        </li>
                    </ul>
                    The ratio between <em>issued vouchers</em> and
                    <em>redeemed vouchers</em> is important to measure the actual success
                    of your stamp card.
                </li>
            </ul>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How to pay an unpaid invoice? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to your profile menu and click the billing menu option.</p>
            <p>
                Select the <strong>Invoices</strong> tab. And press the
                <mat-icon>payments</mat-icon> <strong>Invoice Portal</strong> button.
            </p>
            <p>
                Scroll down to see an overview of your invoices. The one marked
                <strong>unpaid</strong> is the one you need to pay.
            </p>
            <p>
                When an unpaid invoice is left unpaid after 30 days, your subscription
                plan will be terminated and you will lose all data.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Will I lose all members when I cancel my subscription?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Yes. You will lose your members, promotions and stamp cards when you
                cancel your subscription. However, your business account is still
                active. You still have access to your invoices.
            </p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>How to manage your store locations</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I have multiple store locations?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Depending on your subscription plan, you can have multiple store locations.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I run multiple devices in the same store?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Yes, you can. There are no restrictions on how many devices your
                <em>Store User</em> can be logged into.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Will I lose all members when I archive a store location?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                No. Your members are tied to your company, not a specific store
                location. This will make it easier when your store moves to a new
                address or when you are on the road with your food-truck, for example.
            </p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>How to manage your promotions</h2>
    </div>

    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> What is a promotion? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                With a promotion you give away a <strong>free</strong> voucher to your customer. When
                the customer <em>claims the voucher</em>, they'll immediately receive it.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I sell deals to my customers?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>At the moment you can't sell deals to your customers. If you are in need of such feature, please contact
                us. You can find the contact details at our <a href="/contact">Contact</a> page.</p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Can I have multiple promotions? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Depending on your subscription plan, you can have multiple promotions active.
            </p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can customers claim vouchers from the same promotion multiple times?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>At the moment your customers can only <em>claim the voucher</em> <strong>once</strong>. If you are in need of
                such feature, please contact us. You can find the contact details at our <a href="/contact">Contact</a>
                page.</p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What’s the difference between a promotion period and a voucher validity
                    period?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                The promotion period is the period when your promotion is active. During this
                period your customers can <em>grab the deal</em>.
            </p>
            <p>
                The issued voucher can be redeemed, during the voucher validity period.
            </p>
            <p>For example, you can do the following:</p>
            <p>
                Create a promotion with a promotion period from friday until saturday and a
                voucher validity period from monday until wednesday. The promotion can be
                engaged with from friday until saturday. And customers can redeem that
                voucher from monday until wednesday.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Will I lose all members when I archive a promotion?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>No, your members are tied to your company, not a specific promotion.</p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>How to manage your stamp cards</h2>
    </div>

    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I have multiple stamp cards?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Depending on your subscription plan, you can have multiple stamp cards active.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I have a points-based loyalty campaign?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>At the moment we only support stamp cards.</p>
            <p>
                If you are in need of a point-based loyalty system, please drop us a
                line at the <a href="/contact">Contact</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What’s the difference between a stamp card period and a voucher validity
                    period?
                </mat-panel-title>
            </mat-expansion-panel-header>

            <p>
                The stamp card period is the period when your stamp card is active.
                During this period your customers can collect stamps.
            </p>
            <p>
                The issued voucher can be redeemed, during the voucher validity period.
            </p>
            <p>For example, you can do the following:</p>
            <p>
                Create a stamp card with a stamp card period from junary 2024 until
                march 2024 and a voucher validity period for 3 years. Customers can only
                collect stamps from janurary 2024 until march 2024. And customers can
                redeem that voucher until march 2027.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Will I lose all members when I archive a stamp card?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                No, your members are tied to your company, not a specific stamp card.
            </p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>How to use the Planet VG Store app</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I run the Planet VG Store app on multiple devices?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Yes, there are no restrictions on the amount of devices the Planet VG
                Store App can be installed on. Be aware that each store user account is tied to
                a specific store location.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    My employee lost the store user password. How can they login again?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to <strong>Store Locations</strong> page.</p>
            <p>Select the store location your employee works in.</p>
            <p>
                In the <strong>Store User Details</strong> section, press the
                <mat-icon>lock_reset</mat-icon> icon to generate a new store user
                password.
            </p>
            <p>The store username will remain the same.</p>
            <p>Two things will happen:</p>
            <ul>
                <li>You get a pop-up with the new store user password.</li>
                <li>You will receive an email with the new store user password.</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do I reset the store location login?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to <strong>Store Locations</strong> page.</p>
            <p>Select the store location.</p>
            <p>
                In the <strong>Store User Details</strong> section, press the
                <mat-icon>lock_reset</mat-icon> icon to generate a new store user
                password.
            </p>
            <p>The store username will remain the same.</p>
            <p>Two things will happen:</p>
            <ul>
                <li>You get a pop-up with the new store user password.</li>
                <li>You will receive an email with the new store user password.</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How do I issue a stamp </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Log into the Planet VG Store app using the
                <strong>Store User</strong> account for that store location.
            </p>
            <p>On the Home screen:</p>
            <ul>
                <li>Press the <strong>Issue Stamp</strong> button.</li>
                <li>Select the stamp card to issue the stamp for.</li>
                <li>Scan the customer's loyalty card QR code.</li>
            </ul>
            <p>
                <mat-icon>lightbulb_outline</mat-icon> The customer needs to be logged
                into the Planet VG app on their phone. Then they need to do the
                following:
            </p>
            <ul>
                <li>
                    Press the <mat-icon>badge</mat-icon> button to present their loyalty
                    card QR code.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How do I redeem a voucher </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Log into the Planet VG Store app using the
                <strong>Store User</strong> account for that store location.
            </p>
            <p>On the Home screen:</p>
            <ul>
                <li>Press the <strong>Redeem Voucher</strong> button.</li>
                <li>Scan the customer's voucher QR code.</li>
                <li>Give the customer their reward.</li>
            </ul>
            <p>
                <mat-icon>lightbulb_outline</mat-icon> The customer needs to be logged
                into the Planet VG app on their phone. Then they need to do the
                following:
            </p>
            <ul>
                <li>Select the voucher in the <strong>Vouchers</strong> page.</li>
                <li>
                    Press the <mat-icon>redeem</mat-icon>
                    <strong>Redeem Reward</strong> button to present their voucher's QR
                    code.
                </li>
            </ul>
            <p>
                <mat-icon>lightbulb_outline</mat-icon> Your employee will see an error
                popup when their voucher is:
            </p>
            <ul>
                <li>Already redeemed. You can only redeem a voucher once.</li>
                <li>Is not from your company, but a different company.</li>
            </ul>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Your customer forgot to get their stamp, what can I do?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                No worries, you can always issue the stamp when the customer returns
                later on.
            </p>
            <p>You have complete control over when and how you issue stamps.</p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>Security, Data Compliance and Policies</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What Terms of Service do my customers have?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                You can find our user terms on the
                <a href="/user-terms">User Terms</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What Terms of Service do I have as a partner?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You have agree to the following Terms of Services:</p>
            <ul>
                <li>User Terms of Service</li>
                <li>Partner Terms of Service</li>
            </ul>
            <p>
                You can find our user terms on the
                <a href="/user-terms">User Terms</a> page.
            </p>
            <p>
                You can find our partner terms on the
                <a href="/partner-terms">Partner Terms</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> What is the Privacy Policy? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                You can find our privacy policy on the
                <a href="/privacy-policy">Privacy Policy</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do I delete my Planet VG business account?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to <strong>Account</strong> page.</p>
            <p>
                In the <strong>Account Deletion</strong> section, press the
                <mat-icon>delete_outline</mat-icon><strong>Delete Business Account</strong> button.
            </p>
            <p>
                You can only delete your Planet VG business account if you meet the
                following conditions:
            </p>
            <ul>
                <li>You have <strong>no unpaid</strong> invoices.</li>
                <li>You have <strong>canceled</strong> your subscription plan.</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do I delete my Planet VG account?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to the <strong>Profile</strong> page.</p>
            <p>Go to the <strong>Privacy</strong> page</p>
            <p>
                In the <strong>Account Deletion</strong> section, press the
                <mat-icon>delete_outline</mat-icon><strong>Delete Account</strong> button.
            </p>
            <p>
                You can only delete your Planet VG user account if you meet the
                following condition:
            </p>
            <ul>
                <li>You have <strong>no</strong> business account.</li>
            </ul>
            <p>
                See <em>How do I delete my Planet VG business account?</em> on how to
                delete your business account.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What's the difference between a Planet VG account and a Planet VG
                    business account and Planet VG store account?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
                <li>
                    A Planet VG account is an account tied to a person. That can be you or
                    your customers. For you as a partner, this is the account you need to
                    use to log into the Planet VG Partner Portal.
                </li>
                <li>
                    A Planet VG business account is an administrative account for your
                    business. It is connected to your Planet VG user account. Your
                    subscription, promotions, and stamp cards are connected to your
                    business account.
                </li>
                <li>
                    A Planet VG store account is an account for the Planet VG Store app.
                    This account is used to issue stamps and redeem vouchers.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    My phone is stolen or lost, is my account safe?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>No worries, you can do the following:</p>
            <ul>
                <li>
                    Reset your Password: Navigate to the login page and request a reset
                    password. You will receive an email on how to reset your password.
                </li>
                <li>
                    Logout your session: If this is the case contact us, so that we can
                    revoke your logged in sessions.
                </li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>Troubleshooting</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Why can’t my business be found in the Planet VG app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Check if you have an active subscription and no unpaid invoice.</p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    I’m not receiving the password reset email. Please help!
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Have you double checked your spam box?</p>
            <p>You can always retry requesting a password reset.</p>
            <p>If that didn't work, please <a href="/contact">contact</a> us.</p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>Help And Support</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Where can I download the Planet VG Store app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You can find the Planet VG Store app in the following app stores:</p>
            <p>
                <a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty.store"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG Store App - Google Play Store"></a>
                <br />
                <a href="https://apps.apple.com/app/planet-vg-store/id6505109752" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG Store App - Apple App Store"></a>
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Why can't I find the Planet VG Store within the Apple App Store? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Due to the Apple App Store Agreements and Guidelines, we are not allowed to have the Planet VG Store
                publicly available within the Apple App Store.
                However, you can download the Planet VG Store app using the following link:<br /><br />
                <a href="https://apps.apple.com/app/planet-vg-store/id6505109752" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG Store App - Apple App Store"></a>
            </p>

        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Where can my customers download the Planet VG app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You can find the Planet VG app in the following app stores:</p>
            <p>
                <a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG App - Google Play Store"></a>
                <br />
                <a href="https://apps.apple.com/app/planet-vg/id6505109649" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG App - Apple App Store"></a>
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Do my customers need a Planet VG account?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Yes, they do. The loyalty card is tied to a Planet VG account. Your
                customers can create a Planet VG account within the Planet VG app.
            </p>
            <p>You can find the Planet VG app in the following app stores:</p>
            <p>
                <a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG App - Google Play Store"></a>
                <br />
                <a href="https://apps.apple.com/app/planet-vg/id6505109649" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG App - Apple App Store"></a>
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Do vouchers expire? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Yes. You determine the vouchers expiration date.</p>
            <p>For a promotion:</p>
            <ul>
                <li>
                    Specify the <strong><em>Voucher Valid Until Date</em></strong>.
                </li>
            </ul>
            <p>For a stamp card:</p>
            <ul>
                <li>
                    Specify the <strong><em>Voucher Expiration</em></strong>.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do customers update their communication preferences?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Your customers can update their communication preferences using the
                Planet VG app.
            </p>

            <p>They need to do the following:</p>
            <ul>
                <li>
                    Go to the <mat-icon>settings</mat-icon><strong>Settings</strong> page.
                </li>
                <li>
                    Click the <strong>Communication</strong> option.
                </li>
                <li>
                    In the <strong>Email</strong> section they can toggle their email
                    preferences.
                </li>
                <li>
                    In the <strong>Push Notification</strong> section they can toggle
                    their push notification preferences.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Can I log into multiple phones? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Yes, you can.</p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background"
            *ngIf="(isAuthenticated$ | async).isAuthenticated && hasBusinessAccount">
            <mat-expansion-panel-header>
                <mat-panel-title>Didn't find an answer? Contact us.</mat-panel-title>
            </mat-expansion-panel-header>
            <p>Contact the <a href="{{ getMailSupportLink() }}">Planet VG Support Chefs</a>.</p>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<ng-template #noAuth>
    <h2>
        We hope you'll find your answer here. If not, then feel free to
        <a href="/contact">contact</a> the Planet VG Chefs.
    </h2>
</ng-template>