<div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
  <div class="banner-content">
    <h1>About Almost Level 5</h1>
    <img src="/assets/img/planetvg_icon.png" width="75px" alt="planetvg-logo" />
    <h2>
      We empower climate-conscious and vegan businesses to build stronger and lasting connections with their customers
      by providing them climate- and animal-friendly promotions and stamp cards.
    </h2>
  </div>

  <div [ngClass]="{
      'container-card-row-large': isLarge(),
      'container-card-row-medium': isMedium(),
      'container-card-row-small': isSmall(),
      'container-card-row-xsmall': isXSmall()
    }">
    <mat-card appearance="outlined" class="container-banner-profile mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Why do we need to care so much?</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Hi,</p>
          <p>I'm Iskandar, the founder of Almost Level 5. This is me sabotaging a goose hunt
            as a volunteer with
            <a href="https://www.animalrights.nl" target="_blank" rel="noopener">Animal Rights</a>. While sabotaging
            hunts made direct impact for those geese, I've
            always felt that there was another way for me to help save animals and the planet.
          </p>
          <p>
            With Almost Level 5 I have found my way to do my part in saving the
            planet and making a better world for animals.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <img mat-card-image src="/assets/img/profile.jpg" alt="Iskandar-Huntsab" />
      <mat-card-content>
        <div class="container-profile-content">
          <p>Iskandar, Founder</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="container-card">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Our Mission</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Almost Level 5 empowers all climate-conscious and vegan people and businesses alike to change the world for
            the better. Our goal is to bring those people closer together. These bonds are needed if we want to create a
            better place for animals and people as well. A place where everyone is welcome, included, and treated
            fairly.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="container-card">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Our Values</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            The name, Almost Level 5, started out as a well-known vegan meme on the internet. It’s from a Simpson’s
            episode "<a href="https://youtu.be/N_Yaa_LMDcs?si=7YmhfrkS8P7OObnk" target="_blank" rel="noopener">Lisa The
              Tree Hugger</a>". It’s where a person identifies oneself as a level 5 vegan, meaning someone who never
            eats
            anything that casts a shadow.
          </p>
          <p>
            For me, this meme holds a couple of ground truths:
          </p>
          <ul>
            <li>
              <strong>Always strive for the best</strong><br />
              Even though becoming 100% vegan or a level 5 vegan is unattainable, I do believe you should always keep
              striving for it.
            </li>
            <li>
              <strong>Find joy in your life</strong><br />
              There is always someone or something of high value in your life that brings
              joy or a good laugh, even in the face of hardships.
            </li>
            <li>
              <strong>Together</strong><br />
              When you come together to work on the things you highly value, everyone needs to feel and be included.
              When
              striving for the best, everyone needs to feel safe to speak their mind and share their best ideas.
            </li>
            <li>
              <strong>Be Kind</strong><br />
              Everyone's progress in the plant-based lifestyle is different. Accepting that not everyone can run or walk
              as fast as you can, means that you need to give them the opportunity to catch up and even excel.
            </li>
          </ul>
          <p>
            Walking the plant-based journey can be challenging and sometimes even feel depressing. But I want to make
            that journey and challenge a bit more joyful, by providing high-quality vegan services that brings people
            closer
            together.
          </p>
          <p>
            And through this undertaking I would like to build a joyful vegan community, where one can put the burden at
            ease for a little while. When reflecting those memorable moments of your life, you’ll always remember those
            experiences of joy best. The more so if shared amongst like-minded.
          </p>
          <p>So that's why the company is called Almost Level 5.</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="container-card">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>What is Planet VG?</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            Planet VG is a high-quality service, created by us, that empowers like-minded people in
            their vegan way of life or business. We, earthlings, have difficult times ahead
            and we face hard challenges. But we can't change the world alone.
          </p>
          <p>
            We need to walk this path together as <strong>partners</strong>, by creating strong connections amongst
            ourselves, to reinforce our values and build that future we so desire. We need every bit of help
            we can afford in saving the things we highly value.
          </p>
          <p>
            In the past my biggest concern was going to a nice vegan
            restaurant or store, only to find out that it was permanently
            closed. I needed to do something about this and use my time and
            energy to help these businesses stay afloat and thrive.
          </p>
          <p>
            Planet VG gives customers a place to discover new vegan restaurants or even re-kindle interest
            in existing ones. Using this platform like-minded people can connect with eachother and build a
            better world together.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="container-card">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>So, what does VG stand for?</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            VG means a couple of things to me, and hopefully after reading this
            to you as well.
          </p>
          <p>
            First, you pronounce it like <strong><em>Fiji</em></strong>. Fiji is one of the many islands who are in big
            climate
            trouble. If we continue walking down this current path, we not
            only destroy the animals’ habitat but ours as well. We need to start
            caring so much that we will be able to change our path.
          </p>
          <p>
            Second, I love going out to restaurants and I'm always looking for
            the <strong>(vg)</strong> or vegan sign on the menu. It stands for
            the vegan way of life. Which means we need to be more compassionate
            to the planet we live on, animals and people we live with, and
            strive for peace and freedom to all of us.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="container-card">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Our Partners</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <div class="container-column">
          <p>
            When you become a partner, Planet VG will empower your business to grow sustainably with our vegan loyalty
            platform.
            We understand that your customers are very important for your business.
          </p>

          <p>With promotions you can:</p>
          <ul>
            <li>Give away vouchers.</li>
            <li>Bring in new customers.</li>
            <li>Re-engage with your current customers.</li>
          </ul>

          <p>With stamp cards you can:</p>
          <ul>
            <li>Incentivize your customers to keep coming back.</li>
            <li>Let your customers earn reward vouchers.</li>
          </ul>
          <p>
            With Planet VG you will:
          </p>
          <ul>
            <li>Build stronger and lasting connections with your customers.</li>
            <li>Support your customers with their climate and animal rights ambitions.</li>
          </ul>

          <p>To make sure that your growth doesn't add more CO2 into the air, we have partnered with <a
              href="https://www.netcup.eu/ueber-netcup/oekostrom.php" target="_blank" rel="noopener">NetCup, GmbH</a>,
            so that our servers run on renewable green energy.</p>
          <p>
            Let's <strong>(vg)</strong> the planet. Start today and become a <strong>partner</strong> in this endeavor.
          </p>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button color="primary" [routerLink]="['/register']">
          <mat-icon>app_registration</mat-icon>
          Become a Partner
        </button>
      </mat-card-actions>
    </mat-card>
  </div>


</div>
<app-bottom-menu></app-bottom-menu>