<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div class="content-banner-row">
    <div fxLayout="column" fxLayoutAlign="center start" [ngClass]="{
      'container-content-xlarge': isXLarge(),
      'container-content-large': isLarge(),
      'container-content-medium': isMedium(),
      'container-content-small': isSmall(),
      'container-content-xsmall': isXSmall()
    }">
      <div [ngClass]="{
        'content-xlarge': isXLarge(),
        'content-large': isLarge(),
        'content-medium': isMedium(),
        'content-small': isSmall(),
        'content-xsmall': isXSmall()
      }">
        <h1>Grow your business sustainably with our <span class="primary-title">vegan loyalty platform</span></h1>
        <h2>
          With Planet VG you can turn new customers into loyal members. Drive behaviors and engage with your customers
          effortlessly.
        </h2>
        <button mat-raised-button color="primary" routerLink="/register">
          <mat-icon>app_registration</mat-icon>
          Get started today
        </button>
      </div>
    </div>
    <div [ngClass]="{
      'container-product-banner-image-xlarge': isXLarge(),
      'container-product-banner-image-large': isLarge(),
      'container-product-banner-image-medium': isMedium(),
      'container-product-banner-image-small': isSmall(),
      'container-product-banner-image-xsmall': isXSmall()
    }">
      <img src="/assets/img/banners/xlarge/product-banner-headline.webp" alt="planet-vg-vegan-loyalty-platform">
    </div>
  </div>
</div>